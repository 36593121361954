import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgCalendarGradientSolid = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 24, height: 24, fill: "none", "data-icon": "calendargradientsolid", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { fill: "var(--motion-icon-gradient-shade-light, color(display-p3 .9647 .9765 1))", d: "M1.273 4.334a1.905 1.905 0 0 1 1.899-1.748h9.355c1.093 0 1.962.918 1.902 2.01l-.444 8.04a1.905 1.905 0 0 1-1.902 1.799H2.51A1.905 1.905 0 0 1 .61 12.374l.662-8.04Z" }),
  /* @__PURE__ */ jsx("path", { fill: "var(--motion-icon-gradient-shade-dark, color(display-p3 .6039 .6784 .9255))", d: "M2.443 2.356h11.23l.464 2.702H2.041l.402-2.702Z" }),
  /* @__PURE__ */ jsx("path", { fill: "url(#a)", fillRule: "evenodd", d: "M4.643.672a.5.5 0 0 1 .5.5v.52h5.346V1.25a.5.5 0 0 1 1 0v.443h1.046c.923 0 1.687.654 1.865 1.516h.005l1.15 8.688a1.214 1.214 0 0 1-1.205 1.373h-.518a1.906 1.906 0 0 1-1.756 1.165H2.498a1.905 1.905 0 0 1-1.9-2.05l.685-8.933a1.905 1.905 0 0 1 1.899-1.76h.961v-.52a.5.5 0 0 1 .5-.5Zm9.354 11.598.167-3.258.399 3.016a.214.214 0 0 1-.213.242h-.353Zm-1.462-9.577H3.182a.905.905 0 0 0-.902.835L2.2 4.56h11.191l.047-.916a.905.905 0 0 0-.903-.951Zm.805 2.867-.36 7.016a.905.905 0 0 1-.904.859H2.498a.905.905 0 0 1-.903-.974l.53-6.9H13.34Z", clipRule: "evenodd" }),
  /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsxs("linearGradient", { id: "a", x1: 8.079, x2: 8.079, y1: 0.672, y2: 14.435, gradientUnits: "userSpaceOnUse", children: [
    /* @__PURE__ */ jsx("stop", { stopColor: "var(--motion-icon-gradient-bg-top, color(display-p3 .5176 .6078 .898))" }),
    /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "var(--motion-icon-gradient-bg-bottom, color(display-p3 .2392 .3804 .8667))" })
  ] }) })
] });
const ForwardRef = forwardRef(SvgCalendarGradientSolid);
export default ForwardRef;
ForwardRef.displayName = "CalendarGradientSolidIcon";
