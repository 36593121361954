import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgDeadlineWarningSolid = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 24, height: 24, viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", "data-icon": "deadlinewarningsolid", ref, ...props, children: [
  /* @__PURE__ */ jsxs("g", { filter: "url(#filter0_ii_177_13506)", children: [
    /* @__PURE__ */ jsx("rect", { width: 20, height: 20, rx: 4, fill: "url(#paint0_linear_177_13506)" }),
    /* @__PURE__ */ jsx("path", { d: "M10.0134 4.47217C9.34317 4.47214 8.41602 4.95516 8.41602 5.46712L9.25626 11.3633C9.25626 11.8752 9.50148 12.1684 10.0134 12.1684C10.5254 12.1684 10.9404 11.7534 10.9404 11.2414L11.5013 5.46712C11.5013 4.95516 10.6837 4.47217 10.0134 4.47217Z", fill: "white" }),
    /* @__PURE__ */ jsx("path", { d: "M10.0134 15.5267C10.6718 15.5267 11.0607 15.1244 11.0607 14.4538C11.0607 13.8502 10.7388 13.4077 10.0134 13.4077C9.39764 13.4077 8.95505 13.7966 8.95505 14.4538C8.95505 14.9657 9.25626 15.5267 10.0134 15.5267Z", fill: "white" })
  ] }),
  /* @__PURE__ */ jsxs("defs", { children: [
    /* @__PURE__ */ jsxs("filter", { id: "filter0_ii_177_13506", x: 0, y: 0, width: 20, height: 20, filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB", children: [
      /* @__PURE__ */ jsx("feFlood", { floodOpacity: 0, result: "BackgroundImageFix" }),
      /* @__PURE__ */ jsx("feBlend", { mode: "normal", in: "SourceGraphic", in2: "BackgroundImageFix", result: "shape" }),
      /* @__PURE__ */ jsx("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
      /* @__PURE__ */ jsx("feOffset", { dy: -1 }),
      /* @__PURE__ */ jsx("feComposite", { in2: "hardAlpha", operator: "arithmetic", k2: -1, k3: 1 }),
      /* @__PURE__ */ jsx("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.879751 0 0 0 0 0.540926 0 0 0 0 0.263706 0 0 0 1 0" }),
      /* @__PURE__ */ jsx("feBlend", { mode: "normal", in2: "shape", result: "effect1_innerShadow_177_13506" }),
      /* @__PURE__ */ jsx("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
      /* @__PURE__ */ jsx("feOffset", { dy: 1 }),
      /* @__PURE__ */ jsx("feComposite", { in2: "hardAlpha", operator: "arithmetic", k2: -1, k3: 1 }),
      /* @__PURE__ */ jsx("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.972195 0 0 0 0 0.857675 0 0 0 0 0.634088 0 0 0 1 0" }),
      /* @__PURE__ */ jsx("feBlend", { mode: "normal", in2: "effect1_innerShadow_177_13506", result: "effect2_innerShadow_177_13506" })
    ] }),
    /* @__PURE__ */ jsxs("linearGradient", { id: "paint0_linear_177_13506", x1: 10, y1: 0, x2: 10, y2: 20, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { stopColor: "#F4CD71" }),
      /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "#F8A64F" })
    ] })
  ] })
] });
const ForwardRef = forwardRef(SvgDeadlineWarningSolid);
export default ForwardRef;
ForwardRef.displayName = "DeadlineWarningSolidIcon";
