import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgDeadlineScheduledLateSolid = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 24, height: 24, viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", "data-icon": "deadlinescheduledlatesolid", ref, ...props, children: [
  /* @__PURE__ */ jsxs("g", { filter: "url(#filter0_ii_177_13483)", children: [
    /* @__PURE__ */ jsx("rect", { width: 20, height: 20, rx: 4, fill: "url(#paint0_linear_177_13483)" }),
    /* @__PURE__ */ jsx("path", { d: "M6.85742 6.30237C6.85742 5.07102 8.34889 4.53564 8.99891 4.53564C9.56096 4.53564 9.55131 4.89407 9.5384 5.37337C9.53638 5.44839 9.53428 5.52637 9.53428 5.6064V11.0672C9.53428 11.6585 9.05489 12.1379 8.46354 12.1379C7.87218 12.1379 7.39279 11.6585 7.39279 11.0672L6.85742 6.30237Z", fill: "white" }),
    /* @__PURE__ */ jsx("path", { d: "M9.74169 14.3927C9.74169 14.9841 9.2623 15.4635 8.67095 15.4635C8.07959 15.4635 7.6002 14.9841 7.6002 14.3927C7.6002 13.8014 8.07959 13.322 8.67095 13.322C9.2623 13.322 9.74169 13.8014 9.74169 14.3927Z", fill: "white" }),
    /* @__PURE__ */ jsx("path", { d: "M10.7348 5.82677C10.7348 5.23541 11.1677 5.25081 11.8056 5.452C12.4434 5.65319 13.1417 6.40207 13.1417 6.99343L12.8763 11.2876C12.8763 11.8789 12.3969 12.3583 11.8056 12.3583C11.2142 12.3583 10.7348 11.8789 10.7348 11.2876V5.82677Z", fill: "white" }),
    /* @__PURE__ */ jsx("path", { d: "M12.8763 14.3927C12.8763 14.9841 12.3969 15.4635 11.8056 15.4635C11.2142 15.4635 10.7348 14.9841 10.7348 14.3927C10.7348 13.8014 11.2142 13.322 11.8056 13.322C12.3969 13.322 12.8763 13.8014 12.8763 14.3927Z", fill: "white" })
  ] }),
  /* @__PURE__ */ jsxs("defs", { children: [
    /* @__PURE__ */ jsxs("filter", { id: "filter0_ii_177_13483", x: 0, y: 0, width: 20, height: 20, filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB", children: [
      /* @__PURE__ */ jsx("feFlood", { floodOpacity: 0, result: "BackgroundImageFix" }),
      /* @__PURE__ */ jsx("feBlend", { mode: "normal", in: "SourceGraphic", in2: "BackgroundImageFix", result: "shape" }),
      /* @__PURE__ */ jsx("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
      /* @__PURE__ */ jsx("feOffset", { dy: -1 }),
      /* @__PURE__ */ jsx("feComposite", { in2: "hardAlpha", operator: "arithmetic", k2: -1, k3: 1 }),
      /* @__PURE__ */ jsx("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.868854 0 0 0 0 0.346908 0 0 0 0 0.00464901 0 0 0 1 0" }),
      /* @__PURE__ */ jsx("feBlend", { mode: "normal", in2: "shape", result: "effect1_innerShadow_177_13483" }),
      /* @__PURE__ */ jsx("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
      /* @__PURE__ */ jsx("feOffset", { dy: 1 }),
      /* @__PURE__ */ jsx("feComposite", { in2: "hardAlpha", operator: "arithmetic", k2: -1, k3: 1 }),
      /* @__PURE__ */ jsx("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.99801 0 0 0 0 0.761061 0 0 0 0 0.471456 0 0 0 1 0" }),
      /* @__PURE__ */ jsx("feBlend", { mode: "normal", in2: "effect1_innerShadow_177_13483", result: "effect2_innerShadow_177_13483" })
    ] }),
    /* @__PURE__ */ jsxs("linearGradient", { id: "paint0_linear_177_13483", x1: 10, y1: 0, x2: 10, y2: 20, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { stopColor: "#FF9B59" }),
      /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "#F7721B" })
    ] })
  ] })
] });
const ForwardRef = forwardRef(SvgDeadlineScheduledLateSolid);
export default ForwardRef;
ForwardRef.displayName = "DeadlineScheduledLateSolidIcon";
