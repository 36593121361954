import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgDeadlineAheadSolid = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 24, height: 24, viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", "data-icon": "deadlineaheadsolid", ref, ...props, children: [
  /* @__PURE__ */ jsxs("g", { filter: "url(#filter0_ii_177_13521)", children: [
    /* @__PURE__ */ jsx("rect", { width: 20, height: 20, rx: 4, fill: "url(#paint0_linear_177_13521)" }),
    /* @__PURE__ */ jsxs("g", { clipPath: "url(#clip0_177_13521)", children: [
      /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.43375 7.424C10.1137 5.26508 12.7387 3.875 15.6875 3.875C15.8035 3.875 15.9148 3.92109 15.9969 4.00314C16.0789 4.08519 16.125 4.19647 16.125 4.3125C16.125 7.26183 14.7349 9.88625 12.576 11.5668C12.6658 12.1293 12.6325 12.7046 12.4783 13.2529C12.3242 13.8013 12.053 14.3096 11.6833 14.743C11.3136 15.1763 10.8543 15.5243 10.3371 15.7629C9.81992 16.0015 9.25709 16.125 8.6875 16.125C8.57147 16.125 8.46019 16.0789 8.37814 15.9969C8.29609 15.9148 8.25 15.8035 8.25 15.6875V13.2777C7.68296 12.83 7.1704 12.3172 6.72283 11.75H4.3125C4.19647 11.75 4.08519 11.7039 4.00314 11.6219C3.92109 11.5398 3.875 11.4285 3.875 11.3125C3.87495 10.7428 3.99851 10.18 4.23715 9.66271C4.47578 9.14545 4.82381 8.68613 5.25721 8.31645C5.69062 7.94677 6.19907 7.67553 6.74749 7.52145C7.29591 7.36737 7.87123 7.33412 8.43375 7.424ZM11.75 6.9375C11.4019 6.9375 11.0681 7.07578 10.8219 7.32192C10.5758 7.56806 10.4375 7.9019 10.4375 8.25C10.4375 8.5981 10.5758 8.93194 10.8219 9.17808C11.0681 9.42422 11.4019 9.5625 11.75 9.5625C12.0981 9.5625 12.4319 9.42422 12.6781 9.17808C12.9242 8.93194 13.0625 8.5981 13.0625 8.25C13.0625 7.9019 12.9242 7.56806 12.6781 7.32192C12.4319 7.07578 12.0981 6.9375 11.75 6.9375Z", fill: "white" }),
      /* @__PURE__ */ jsx("path", { d: "M6.06878 13.0576C6.11486 13.0232 6.15372 12.9801 6.18314 12.9308C6.21256 12.8814 6.23197 12.8267 6.24026 12.7698C6.24855 12.713 6.24555 12.655 6.23144 12.5993C6.21733 12.5436 6.19239 12.4912 6.15803 12.4451C6.12367 12.399 6.08058 12.3602 6.0312 12.3307C5.98183 12.3013 5.92714 12.2819 5.87027 12.2736C5.81339 12.2653 5.75544 12.2683 5.69972 12.2824C5.644 12.2965 5.59161 12.3215 5.54553 12.3558C5.09805 12.6887 4.7501 13.1376 4.53933 13.6539C4.32856 14.1703 4.26298 14.7344 4.3497 15.2853C4.3638 15.3771 4.40673 15.462 4.47227 15.5278C4.53781 15.5935 4.62257 15.6367 4.71428 15.6511C5.26526 15.7377 5.82944 15.6721 6.34579 15.4612C6.86214 15.2503 7.31099 14.9023 7.64378 14.4547C7.67925 14.4087 7.70521 14.3562 7.72016 14.3001C7.73511 14.244 7.73876 14.1855 7.73087 14.128C7.72299 14.0705 7.70374 14.0151 7.67425 13.9651C7.64475 13.9151 7.60561 13.8715 7.55908 13.8368C7.51256 13.802 7.4596 13.7769 7.40327 13.7629C7.34695 13.7488 7.28839 13.7461 7.23102 13.755C7.17364 13.7638 7.1186 13.7839 7.06908 13.8142C7.01957 13.8445 6.97658 13.8844 6.94262 13.9314C6.73919 14.205 6.47453 14.4272 6.16984 14.5801C5.86515 14.7331 5.52888 14.8126 5.18795 14.8123C5.18795 14.0948 5.53328 13.4572 6.06878 13.0576Z", fill: "white" })
    ] })
  ] }),
  /* @__PURE__ */ jsxs("defs", { children: [
    /* @__PURE__ */ jsxs("filter", { id: "filter0_ii_177_13521", x: 0, y: 0, width: 20, height: 20, filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB", children: [
      /* @__PURE__ */ jsx("feFlood", { floodOpacity: 0, result: "BackgroundImageFix" }),
      /* @__PURE__ */ jsx("feBlend", { mode: "normal", in: "SourceGraphic", in2: "BackgroundImageFix", result: "shape" }),
      /* @__PURE__ */ jsx("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
      /* @__PURE__ */ jsx("feOffset", { dy: -1 }),
      /* @__PURE__ */ jsx("feComposite", { in2: "hardAlpha", operator: "arithmetic", k2: -1, k3: 1 }),
      /* @__PURE__ */ jsx("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.0346191 0 0 0 0 0.465257 0 0 0 0 0.56193 0 0 0 1 0" }),
      /* @__PURE__ */ jsx("feBlend", { mode: "normal", in2: "shape", result: "effect1_innerShadow_177_13521" }),
      /* @__PURE__ */ jsx("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
      /* @__PURE__ */ jsx("feOffset", { dy: 1 }),
      /* @__PURE__ */ jsx("feComposite", { in2: "hardAlpha", operator: "arithmetic", k2: -1, k3: 1 }),
      /* @__PURE__ */ jsx("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.552126 0 0 0 0 0.962499 0 0 0 0 0.97665 0 0 0 1 0" }),
      /* @__PURE__ */ jsx("feBlend", { mode: "normal", in2: "effect1_innerShadow_177_13521", result: "effect2_innerShadow_177_13521" })
    ] }),
    /* @__PURE__ */ jsxs("linearGradient", { id: "paint0_linear_177_13521", x1: 10, y1: 0, x2: 10, y2: 20, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { stopColor: "#01E6EA" }),
      /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "#1197C0" })
    ] }),
    /* @__PURE__ */ jsx("clipPath", { id: "clip0_177_13521", children: /* @__PURE__ */ jsx("rect", { width: 14, height: 14, fill: "white", transform: "translate(3 3)" }) })
  ] })
] });
const ForwardRef = forwardRef(SvgDeadlineAheadSolid);
export default ForwardRef;
ForwardRef.displayName = "DeadlineAheadSolidIcon";
