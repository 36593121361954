import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgPlayButtonSolid = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 24, height: 24, viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", "data-icon": "playbuttonsolid", ref, ...props, children: [
  /* @__PURE__ */ jsx("g", { opacity: 0.8, filter: "url(#filter0_b_313_1408)", children: /* @__PURE__ */ jsx("path", { d: "M9 18C13.9706 18 18 13.9705 18 8.99998C18 4.02942 13.9706 0 9 0C4.02944 0 0 4.02942 0 8.99998C0 13.9705 4.02944 18 9 18Z", fill: "black", fillOpacity: 0.2 }) }),
  /* @__PURE__ */ jsx("path", { d: "M9 17C13.4183 17 17 13.4183 17 8.99998C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 8.99998C1 13.4183 4.58172 17 9 17Z", fill: "white" }),
  /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9 16.8C13.3078 16.8 16.8 13.3078 16.8 8.99998C16.8 4.69218 13.3078 1.2 9 1.2C4.69218 1.2 1.2 4.69218 1.2 8.99998C1.2 13.3078 4.69218 16.8 9 16.8ZM17 8.99998C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 8.99998C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 8.99998Z", fill: "black", fillOpacity: 0.12 }),
  /* @__PURE__ */ jsx("path", { opacity: 0.7, d: "M7 6.65361V11.3464C7 11.7042 7.41164 11.9217 7.72864 11.7269L11.58 9.38051C11.8733 9.20385 11.8733 8.79617 11.58 8.61499L7.72864 6.27313C7.41164 6.07835 7 6.29577 7 6.65361Z", fill: "black" }),
  /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsxs("filter", { id: "filter0_b_313_1408", x: -16.875, y: -16.875, width: 51.75, height: 51.75, filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB", children: [
    /* @__PURE__ */ jsx("feFlood", { floodOpacity: 0, result: "BackgroundImageFix" }),
    /* @__PURE__ */ jsx("feGaussianBlur", { in: "BackgroundImageFix", stdDeviation: 8.4375 }),
    /* @__PURE__ */ jsx("feComposite", { in2: "SourceAlpha", operator: "in", result: "effect1_backgroundBlur_313_1408" }),
    /* @__PURE__ */ jsx("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_backgroundBlur_313_1408", result: "shape" })
  ] }) })
] });
const ForwardRef = forwardRef(SvgPlayButtonSolid);
export default ForwardRef;
ForwardRef.displayName = "PlayButtonSolidIcon";
