import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgMicrosoftLogo = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 24, height: 24, viewBox: "0 0 21 21", "data-icon": "microsoftlogo", ref, ...props, children: [
  /* @__PURE__ */ jsx("title", { children: "MS-SymbolLockup" }),
  /* @__PURE__ */ jsx("rect", { x: 1, y: 1, width: 9, height: 9, fill: "#f25022" }),
  /* @__PURE__ */ jsx("rect", { x: 1, y: 11, width: 9, height: 9, fill: "#00a4ef" }),
  /* @__PURE__ */ jsx("rect", { x: 11, y: 1, width: 9, height: 9, fill: "#7fba00" }),
  /* @__PURE__ */ jsx("rect", { x: 11, y: 11, width: 9, height: 9, fill: "#ffb900" })
] });
const ForwardRef = forwardRef(SvgMicrosoftLogo);
export default ForwardRef;
ForwardRef.displayName = "MicrosoftLogoIcon";
