import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgStatusCompletedSolid = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 24, height: 24, viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", "data-icon": "statuscompletedsolid", ref, ...props, children: [
  /* @__PURE__ */ jsx("defs", {}),
  /* @__PURE__ */ jsx("circle", { cx: 9, cy: 9, r: 8, fill: "#30A66D" }),
  /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M12.4826 5.87087C12.7611 6.1029 12.7987 6.51671 12.5666 6.79514L8.52774 11.6418C8.09047 12.1666 7.28454 12.1666 6.84726 11.6418L4.99586 9.42014C4.76383 9.14171 4.80145 8.7279 5.07988 8.49587C5.35831 8.26384 5.77212 8.30146 6.00415 8.5799L7.6875 10.5999L11.5584 5.9549C11.7904 5.67646 12.2042 5.63884 12.4826 5.87087Z", fill: "white" })
] });
const ForwardRef = forwardRef(SvgStatusCompletedSolid);
export default ForwardRef;
ForwardRef.displayName = "StatusCompletedSolidIcon";
