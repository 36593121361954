import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgArrowRightGradientSolid = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 24, height: 24, viewBox: "0 0 14 12", fill: "none", xmlns: "http://www.w3.org/2000/svg", "data-icon": "arrowrightgradientsolid", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.23429 0.634297C7.54671 0.321878 8.05324 0.321878 8.36566 0.634297L13.1657 5.4343C13.4781 5.74672 13.4781 6.25325 13.1657 6.56567L8.36566 11.3657C8.05324 11.6781 7.54671 11.6781 7.23429 11.3657C6.92187 11.0532 6.92187 10.5467 7.23429 10.2343L10.6686 6.79998L1.39998 6.79998C0.958149 6.79998 0.599976 6.44181 0.599976 5.99998C0.599976 5.55816 0.958149 5.19998 1.39998 5.19998H10.6686L7.23429 1.76567C6.92187 1.45325 6.92187 0.946717 7.23429 0.634297Z", fill: "url(#paint0_linear_467_506)" }),
  /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsxs("linearGradient", { id: "paint0_linear_467_506", x1: 0.599976, y1: 5.99998, x2: 13.4, y2: 5.99998, gradientUnits: "userSpaceOnUse", children: [
    /* @__PURE__ */ jsx("stop", { stopColor: "var(--svg-stop-color-0,#AA47F7)" }),
    /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "var(--svg-stop-color-1,#F147EA)" })
  ] }) })
] });
const ForwardRef = forwardRef(SvgArrowRightGradientSolid);
export default ForwardRef;
ForwardRef.displayName = "ArrowRightGradientSolidIcon";
