import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgLight = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 24, height: 24, viewBox: "0 0 81 53", fill: "none", xmlns: "http://www.w3.org/2000/svg", "data-icon": "light", ref, ...props, children: [
  /* @__PURE__ */ jsx("g", { clipPath: "url(#clip0_652_2910)", children: /* @__PURE__ */ jsxs("g", { clipPath: "url(#clip1_652_2910)", children: [
    /* @__PURE__ */ jsx("rect", { width: 81, height: 49.2143, transform: "translate(2 2)", fill: "#E6E8EB" }),
    /* @__PURE__ */ jsx("rect", { x: 9.90958, y: 9.90958, width: 79.0627, height: 52.5786, rx: 2.92104, fill: "white" }),
    /* @__PURE__ */ jsx("rect", { x: 9.90958, y: 9.90958, width: 79.0627, height: 52.5786, rx: 2.92104, stroke: "#C1C8CD", strokeWidth: 0.389471 }),
    /* @__PURE__ */ jsx("rect", { x: 12.4414, y: 12.832, width: 22.5893, height: 46.7366, rx: 1.55789, fill: "#E6E8EB" }),
    /* @__PURE__ */ jsx("rect", { x: 37.7568, y: 12.832, width: 22.5893, height: 46.7366, rx: 1.55789, fill: "#E6E8EB" }),
    /* @__PURE__ */ jsx("rect", { x: 63.0723, y: 12.832, width: 22.5893, height: 46.7366, rx: 1.55789, fill: "#E6E8EB" })
  ] }) }),
  /* @__PURE__ */ jsxs("defs", { children: [
    /* @__PURE__ */ jsx("clipPath", { id: "clip0_652_2910", children: /* @__PURE__ */ jsx("rect", { x: 2, y: 2, width: 76.6667, height: 49, rx: 4, fill: "white" }) }),
    /* @__PURE__ */ jsx("clipPath", { id: "clip1_652_2910", children: /* @__PURE__ */ jsx("rect", { width: 81, height: 49.2143, fill: "white", transform: "translate(2 2)" }) })
  ] })
] });
const ForwardRef = forwardRef(SvgLight);
export default ForwardRef;
ForwardRef.displayName = "LightIcon";
