// @ts-expect-error - virtual path
// eslint-disable-next-line import-x/no-unresolved
import { SpriteSheetUrl } from '!svg-sheet'
import { forwardRef } from 'react'

import { type SvgIconProps } from './types'

export default function createIconComponentFromSheet(id: string) {
  const Component = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
    return (
      <svg data-icon={id} ref={ref} width='24' height='24' {...props}>
        <use xlinkHref={`${SpriteSheetUrl}#${id}`} />
      </svg>
    )
  })
  Component.displayName = id
  return Component
}
