import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgTeamScheduleGradientSolid = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", width: 24, height: 24, fill: "none", "data-icon": "teamschedulegradientsolid", ref, ...props, children: [
  /* @__PURE__ */ jsxs("g", { clipPath: "url(#a)", children: [
    /* @__PURE__ */ jsx("path", { fill: "var(--motion-icon-gradient-shade-light, color(display-p3 .9647 .9765 1))", d: "m3.755 8.61-.029 1.636.029-1.636Zm2.21-1.635-.058 3.271.058-3.271Zm2.21-1.636-.086 4.907.085-4.907Zm2.21-1.636-.115 6.543.114-6.543Zm-7.797 9.27h8.725c.434 0 .853-.173 1.165-.48.313-.307.492-.723.5-1.157l.152-8.723a1.608 1.608 0 0 0-.459-1.157 1.608 1.608 0 0 0-1.148-.48H2.797c-.434 0-.853.173-1.165.48a1.665 1.665 0 0 0-.5 1.157L.98 11.336a1.607 1.607 0 0 0 1.607 1.636Z" }),
    /* @__PURE__ */ jsx("path", { stroke: "url(#b)", strokeLinecap: "round", strokeLinejoin: "round", d: "m3.755 8.61-.029 1.636m2.239-3.271-.058 3.271m2.267-4.907-.085 4.907m2.295-6.543-.114 6.543m-7.682 2.726h8.725c.434 0 .853-.172 1.165-.479.313-.307.492-.723.5-1.157l.152-8.723a1.608 1.608 0 0 0-.459-1.157 1.608 1.608 0 0 0-1.148-.48H2.797c-.434 0-.853.173-1.165.48a1.665 1.665 0 0 0-.5 1.157L.98 11.336a1.607 1.607 0 0 0 1.607 1.636Z" }),
    /* @__PURE__ */ jsx("circle", { cx: 11.113, cy: 11.266, r: 3.923, fill: "var(--motion-icon-gradient-shade-light, color(display-p3 .9647 .9765 1))", stroke: "url(#c)", strokeLinecap: "round", strokeLinejoin: "round" }),
    /* @__PURE__ */ jsx("path", { stroke: "url(#d)", strokeLinecap: "round", strokeLinejoin: "round", d: "m11.188 9.12-.287 2.484 1.177 1.368" })
  ] }),
  /* @__PURE__ */ jsxs("defs", { children: [
    /* @__PURE__ */ jsxs("linearGradient", { id: "b", x1: 7.16, x2: 7.16, y1: 0.977, y2: 12.972, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { stopColor: "var(--motion-icon-gradient-bg-top, color(display-p3 .5176 .6078 .898))" }),
      /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "var(--motion-icon-gradient-bg-bottom, color(display-p3 .2392 .3804 .8667))" })
    ] }),
    /* @__PURE__ */ jsxs("linearGradient", { id: "c", x1: 11.113, x2: 11.113, y1: 7.343, y2: 15.189, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { stopColor: "var(--motion-icon-gradient-bg-top, color(display-p3 .5176 .6078 .898))" }),
      /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "var(--motion-icon-gradient-bg-bottom, color(display-p3 .2392 .3804 .8667))" })
    ] }),
    /* @__PURE__ */ jsxs("linearGradient", { id: "d", x1: 11.489, x2: 11.489, y1: 9.12, y2: 12.972, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { stopColor: "var(--motion-icon-gradient-bg-top, color(display-p3 .5176 .6078 .898))" }),
      /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "var(--motion-icon-gradient-bg-bottom, color(display-p3 .2392 .3804 .8667))" })
    ] }),
    /* @__PURE__ */ jsx("clipPath", { id: "a", children: /* @__PURE__ */ jsx("path", { fill: "var(--motion-icon-gradient-shade-light, color(display-p3 .9647 .9765 1))", d: "M0 0h16v16H0z" }) })
  ] })
] });
const ForwardRef = forwardRef(SvgTeamScheduleGradientSolid);
export default ForwardRef;
ForwardRef.displayName = "TeamScheduleGradientSolidIcon";
