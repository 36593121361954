import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgDeadlineMissedSolid = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 24, height: 24, viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg", "data-icon": "deadlinemissedsolid", ref, ...props, children: [
  /* @__PURE__ */ jsxs("g", { filter: "url(#filter0_ii_177_13482)", children: [
    /* @__PURE__ */ jsx("rect", { width: 20, height: 20, rx: 4, fill: "url(#paint0_linear_177_13482)" }),
    /* @__PURE__ */ jsx("path", { d: "M9.99976 3.81429C9.30478 3.59507 8.83309 3.5783 8.83309 4.22263L9.0468 11.7885C9.0468 12.4328 9.35542 12.8018 9.99976 12.8018C10.6441 12.8018 11.1664 12.2795 11.1664 11.6352L11.4556 5.4938C11.4556 4.84947 10.6947 4.0335 9.99976 3.81429Z", fill: "white" }),
    /* @__PURE__ */ jsx("path", { d: "M9.99976 16.3225C10.6441 16.3225 11.1664 15.8002 11.1664 15.1558C11.1664 14.5115 10.6441 13.9892 9.99976 13.9892C9.35542 13.9892 8.83309 14.5115 8.83309 15.1558C8.83309 15.8002 9.35542 16.3225 9.99976 16.3225Z", fill: "white" }),
    /* @__PURE__ */ jsx("path", { d: "M14.0111 5.3356C13.326 5.08718 12.8555 5.05046 12.8282 5.69422L12.7735 11.7106C12.7463 12.3544 13.1577 12.7178 13.8015 12.7451C14.4452 12.7723 14.6374 12.1738 14.6647 11.5301L15.3945 7.07522C15.4218 6.43146 14.6962 5.58403 14.0111 5.3356Z", fill: "white" }),
    /* @__PURE__ */ jsx("path", { d: "M13.3781 16.0317C14.0219 16.059 14.4507 15.8048 14.478 15.161C14.5052 14.5173 14.1746 14.0273 13.5309 14.0001C12.8871 13.9728 12.4736 14.2881 12.4463 14.9319C12.419 15.5756 12.7344 16.0045 13.3781 16.0317Z", fill: "white" }),
    /* @__PURE__ */ jsx("path", { d: "M4.76172 6.314C4.76172 4.97233 6.02945 4.25097 6.73771 4.25097C7.35011 4.25097 7.33959 4.6415 7.32553 5.16374C7.32333 5.24548 7.32104 5.33045 7.32104 5.41765L7.49249 11.4393C7.49249 12.0836 7.3275 12.744 6.68317 12.744C6.03883 12.744 5.6535 12.3757 5.6535 11.7314L4.76172 6.314Z", fill: "white" }),
    /* @__PURE__ */ jsx("path", { d: "M7.82814 15.1291C7.82814 15.7734 7.445 16.181 6.80067 16.181C6.15634 16.181 5.6535 15.7734 5.6535 15.1291C5.6535 14.4848 6.09338 14.0463 6.73771 14.0463C7.38204 14.0463 7.82814 14.4848 7.82814 15.1291Z", fill: "white" })
  ] }),
  /* @__PURE__ */ jsxs("defs", { children: [
    /* @__PURE__ */ jsxs("filter", { id: "filter0_ii_177_13482", x: 0, y: 0, width: 20, height: 20, filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB", children: [
      /* @__PURE__ */ jsx("feFlood", { floodOpacity: 0, result: "BackgroundImageFix" }),
      /* @__PURE__ */ jsx("feBlend", { mode: "normal", in: "SourceGraphic", in2: "BackgroundImageFix", result: "shape" }),
      /* @__PURE__ */ jsx("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
      /* @__PURE__ */ jsx("feOffset", { dy: -1 }),
      /* @__PURE__ */ jsx("feComposite", { in2: "hardAlpha", operator: "arithmetic", k2: -1, k3: 1 }),
      /* @__PURE__ */ jsx("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.613608 0 0 0 0 0.217349 0 0 0 0 0.249478 0 0 0 1 0" }),
      /* @__PURE__ */ jsx("feBlend", { mode: "normal", in2: "shape", result: "effect1_innerShadow_177_13482" }),
      /* @__PURE__ */ jsx("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
      /* @__PURE__ */ jsx("feOffset", { dy: 1 }),
      /* @__PURE__ */ jsx("feComposite", { in2: "hardAlpha", operator: "arithmetic", k2: -1, k3: 1 }),
      /* @__PURE__ */ jsx("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.93783 0 0 0 0 0.530692 0 0 0 0 0.535166 0 0 0 1 0" }),
      /* @__PURE__ */ jsx("feBlend", { mode: "normal", in2: "effect1_innerShadow_177_13482", result: "effect2_innerShadow_177_13482" })
    ] }),
    /* @__PURE__ */ jsxs("linearGradient", { id: "paint0_linear_177_13482", x1: 10, y1: 0, x2: 10, y2: 20, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { stopColor: "#E86367" }),
      /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "#CE2C31" })
    ] })
  ] })
] });
const ForwardRef = forwardRef(SvgDeadlineMissedSolid);
export default ForwardRef;
ForwardRef.displayName = "DeadlineMissedSolidIcon";
