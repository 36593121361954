import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgProjectGradientSolid = (props, ref) => /* @__PURE__ */ jsxs("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", fill: "none", "data-icon": "projectgradientsolid", width: 24, height: 24, ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { fill: "var(--svg-shade-dark, var(--motion-icon-gradient-shade-dark, color(display-p3 .6039 .6784 .9255)))", d: "M7.94 7.587c0-.416-.25-.792-.633-.955l-4.53-1.924a1.038 1.038 0 0 0-1.444.955v5.523c0 .384.212.737.551.917l4.53 2.405a1.038 1.038 0 0 0 1.525-.917V7.587Z" }),
  /* @__PURE__ */ jsx("path", { fill: "var(--svg-shade-medium, var(--motion-icon-gradient-shade-medium, color(display-p3 .7922 .8314 .9569)))", d: "M14.5 11.155c0 .381-.21.732-.545.913L9.471 14.49a1.038 1.038 0 0 1-1.532-.913v-5.95c0-.411.243-.784.62-.95L13.042 4.7c.686-.302 1.457.2 1.457.95v5.505Z" }),
  /* @__PURE__ */ jsx("path", { fill: "var(--svg-shade-light, var(--motion-icon-gradient-shade-light, color(display-p3 .9647 .9765 1)))", d: "M8.142 6.573c-.27.116-.578.112-.846-.012L2.254 4.23a.208.208 0 0 1-.014-.37L7.434.964a1.038 1.038 0 0 1 1.01 0l5.183 2.888c.15.084.139.304-.02.372L8.143 6.573Z" }),
  /* @__PURE__ */ jsx("path", { fill: "url(#a)", fillRule: "evenodd", d: "M6.402 1.199a3.459 3.459 0 0 1 3.11-.009l3.985 1.992a2.473 2.473 0 0 1 1.367 2.212v5.143c0 .926-.517 1.775-1.341 2.199L9.547 14.78a3.46 3.46 0 0 1-3.181-.009l-3.901-2.034a2.473 2.473 0 0 1-1.33-2.193V5.386c0-.931.524-1.784 1.355-2.205l3.912-1.982Zm2.662.886a2.459 2.459 0 0 0-2.21.006L2.942 4.073c-.064.033-.126.07-.184.11l4.608 2.16c.4.186.862.184 1.26-.006l4.56-2.183a1.468 1.468 0 0 0-.136-.077L9.064 2.085ZM2.135 5.386c0-.126.017-.25.047-.368l4.76 2.23c.166.077.338.136.514.175v6.687a2.457 2.457 0 0 1-.628-.226l-3.9-2.034a1.473 1.473 0 0 1-.793-1.306V5.386Zm6.32 8.727c.219-.044.432-.118.635-.222l3.975-2.045c.491-.252.8-.758.8-1.31V5.395c0-.147-.022-.29-.063-.426l-4.744 2.27a2.472 2.472 0 0 1-.602.2v6.676Z", clipRule: "evenodd" }),
  /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsxs("linearGradient", { id: "a", x1: 8, x2: 8, y1: 0.825, y2: 15.163, gradientUnits: "userSpaceOnUse", children: [
    /* @__PURE__ */ jsx("stop", { stopColor: "var(--svg-stop-color-0, var(--motion-icon-gradient-bg-top, color(display-p3 .5176 .6078 .898)))" }),
    /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "var(--svg-stop-color-1, var(--motion-icon-gradient-bg-bottom, color(display-p3 .2392 .3804 .8667)))" })
  ] }) })
] });
const ForwardRef = forwardRef(SvgProjectGradientSolid);
export default ForwardRef;
ForwardRef.displayName = "ProjectGradientSolidIcon";
