import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgSystem = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 24, height: 24, viewBox: "0 0 81 53", fill: "none", xmlns: "http://www.w3.org/2000/svg", "data-icon": "system", ref, ...props, children: [
  /* @__PURE__ */ jsxs("g", { clipPath: "url(#clip0_652_2922)", children: [
    /* @__PURE__ */ jsxs("g", { clipPath: "url(#clip1_652_2922)", children: [
      /* @__PURE__ */ jsx("rect", { width: 81, height: 49.2143, transform: "translate(2.33301 2)", fill: "#E6E8EB" }),
      /* @__PURE__ */ jsx("rect", { x: 10.2416, y: 9.90958, width: 79.0627, height: 52.5786, rx: 2.92104, fill: "white" }),
      /* @__PURE__ */ jsx("rect", { x: 10.2416, y: 9.90958, width: 79.0627, height: 52.5786, rx: 2.92104, stroke: "#C1C8CD", strokeWidth: 0.389471 }),
      /* @__PURE__ */ jsx("rect", { x: 12.7734, y: 12.832, width: 22.5893, height: 46.7366, rx: 1.55789, fill: "#E6E8EB" }),
      /* @__PURE__ */ jsx("rect", { x: 38.0889, y: 12.832, width: 22.5893, height: 46.7366, rx: 1.55789, fill: "#E6E8EB" }),
      /* @__PURE__ */ jsx("rect", { x: 63.4043, y: 12.832, width: 22.5893, height: 46.7366, rx: 1.55789, fill: "#E6E8EB" })
    ] }),
    /* @__PURE__ */ jsx("g", { clipPath: "url(#clip2_652_2922)", children: /* @__PURE__ */ jsxs("g", { clipPath: "url(#clip3_652_2922)", children: [
      /* @__PURE__ */ jsx("rect", { width: 81, height: 49.2143, transform: "translate(42.082 2)", fill: "#3F4448" }),
      /* @__PURE__ */ jsx("rect", { x: 49.9916, y: 9.90958, width: 79.0627, height: 52.5786, rx: 2.92104, fill: "#1A1D1E" }),
      /* @__PURE__ */ jsx("rect", { x: 49.9916, y: 9.90958, width: 79.0627, height: 52.5786, rx: 2.92104, stroke: "#3F4448", strokeWidth: 0.389471 }),
      /* @__PURE__ */ jsx("rect", { x: 52.5234, y: 12.832, width: 22.5893, height: 46.7366, rx: 1.55789, fill: "#3F4448" }),
      /* @__PURE__ */ jsx("rect", { x: 77.8389, y: 12.832, width: 22.5893, height: 46.7366, rx: 1.55789, fill: "#3F4448" })
    ] }) })
  ] }),
  /* @__PURE__ */ jsxs("defs", { children: [
    /* @__PURE__ */ jsx("clipPath", { id: "clip0_652_2922", children: /* @__PURE__ */ jsx("rect", { x: 2.33301, y: 2, width: 76.6667, height: 49, rx: 4, fill: "white" }) }),
    /* @__PURE__ */ jsx("clipPath", { id: "clip1_652_2922", children: /* @__PURE__ */ jsx("rect", { width: 81, height: 49.2143, fill: "white", transform: "translate(2.33301 2)" }) }),
    /* @__PURE__ */ jsx("clipPath", { id: "clip2_652_2922", children: /* @__PURE__ */ jsx("rect", { width: 81, height: 49, fill: "white", transform: "translate(42.082 2)" }) }),
    /* @__PURE__ */ jsx("clipPath", { id: "clip3_652_2922", children: /* @__PURE__ */ jsx("rect", { width: 81, height: 49.2143, fill: "white", transform: "translate(42.082 2)" }) })
  ] })
] });
const ForwardRef = forwardRef(SvgSystem);
export default ForwardRef;
ForwardRef.displayName = "SystemIcon";
