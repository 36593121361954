import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgDeadlineOnTimeSolid = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 24, height: 24, viewBox: "0 0 59 59", fill: "none", xmlns: "http://www.w3.org/2000/svg", "data-icon": "deadlineontimesolid", ref, ...props, children: [
  /* @__PURE__ */ jsxs("g", { filter: "url(#filter0_ii_1822_70829)", children: [
    /* @__PURE__ */ jsx("path", { d: "M0.435547 12.3887C0.435547 6.0218 5.59698 0.860352 11.9639 0.860352H46.5491C52.916 0.860352 58.0774 6.02178 58.0774 12.3887V46.9739C58.0774 53.3408 52.916 58.5023 46.5491 58.5023H11.9639C5.59699 58.5023 0.435547 53.3408 0.435547 46.9739V12.3887Z", fill: "url(#paint0_linear_1822_70829)" }),
    /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14.9911 15.4159C20.6752 9.73175 28.9072 8.14846 36.0107 10.666L32.5107 14.1657C27.4513 13.1096 21.9755 14.5452 18.048 18.4728C11.8576 24.6632 11.8576 34.6998 18.048 40.8902C24.2384 47.0806 34.275 47.0806 40.4654 40.8902C44.3958 36.9599 45.8307 31.4791 44.7702 26.4167L48.4015 23.3041C50.7327 30.3204 49.1063 38.3632 43.5223 43.9471C35.6436 51.8258 22.8698 51.8258 14.9911 43.9471C7.11236 36.0684 7.11236 23.2946 14.9911 15.4159ZM42.5019 10.3216L43.2089 16.1055L43.5061 16.4027L48.6158 16.4355L42.5019 22.5493L39.6622 22.3321L33.2277 28.7667C33.6129 30.2089 33.2397 31.8116 32.1082 32.9431C30.4199 34.6314 27.6827 34.6314 25.9944 32.9431C24.3061 31.2548 24.3061 28.5175 25.9944 26.8292C27.1259 25.6977 28.7285 25.3246 30.1708 25.7097L36.8777 19.0028L36.3881 16.4355L42.5019 10.3216Z", fill: "white" })
  ] }),
  /* @__PURE__ */ jsxs("defs", { children: [
    /* @__PURE__ */ jsxs("filter", { id: "filter0_ii_1822_70829", x: 0.435547, y: 0.860352, width: 57.6416, height: 57.6421, filterUnits: "userSpaceOnUse", colorInterpolationFilters: "sRGB", children: [
      /* @__PURE__ */ jsx("feFlood", { floodOpacity: 0, result: "BackgroundImageFix" }),
      /* @__PURE__ */ jsx("feBlend", { mode: "normal", in: "SourceGraphic", in2: "BackgroundImageFix", result: "shape" }),
      /* @__PURE__ */ jsx("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
      /* @__PURE__ */ jsx("feOffset", { dy: -2.8821 }),
      /* @__PURE__ */ jsx("feComposite", { in2: "hardAlpha", operator: "arithmetic", k2: -1, k3: 1 }),
      /* @__PURE__ */ jsx("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.223704 0 0 0 0 0.624505 0 0 0 0 0.284203 0 0 0 1 0" }),
      /* @__PURE__ */ jsx("feBlend", { mode: "normal", in2: "shape", result: "effect1_innerShadow_1822_70829" }),
      /* @__PURE__ */ jsx("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
      /* @__PURE__ */ jsx("feOffset", { dy: 2.8821 }),
      /* @__PURE__ */ jsx("feComposite", { in2: "hardAlpha", operator: "arithmetic", k2: -1, k3: 1 }),
      /* @__PURE__ */ jsx("feColorMatrix", { type: "matrix", values: "0 0 0 0 0.661086 0 0 0 0 0.935198 0 0 0 0 0.628311 0 0 0 1 0" }),
      /* @__PURE__ */ jsx("feBlend", { mode: "normal", in2: "effect1_innerShadow_1822_70829", result: "effect2_innerShadow_1822_70829" })
    ] }),
    /* @__PURE__ */ jsxs("linearGradient", { id: "paint0_linear_1822_70829", x1: 29.2565, y1: 0.860352, x2: 29.2565, y2: 58.5023, gradientUnits: "userSpaceOnUse", children: [
      /* @__PURE__ */ jsx("stop", { stopColor: "#8DE77D" }),
      /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "#46B25A" })
    ] })
  ] })
] });
const ForwardRef = forwardRef(SvgDeadlineOnTimeSolid);
export default ForwardRef;
ForwardRef.displayName = "DeadlineOnTimeSolidIcon";
