import { jsx, jsxs } from "react/jsx-runtime";
import { forwardRef } from "react";
const SvgCheckCircleGradientSolid = (props, ref) => /* @__PURE__ */ jsxs("svg", { width: 24, height: 24, viewBox: "0 0 16 17", fill: "none", xmlns: "http://www.w3.org/2000/svg", "data-icon": "checkcirclegradientsolid", ref, ...props, children: [
  /* @__PURE__ */ jsx("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M7.99994 14.9C11.5346 14.9 14.3999 12.0346 14.3999 8.50002C14.3999 4.9654 11.5346 2.10002 7.99994 2.10002C4.46531 2.10002 1.59994 4.9654 1.59994 8.50002C1.59994 12.0346 4.46531 14.9 7.99994 14.9ZM10.9656 7.46571C11.278 7.15329 11.278 6.64675 10.9656 6.33434C10.6532 6.02192 10.1467 6.02192 9.83425 6.33434L7.19993 8.96865L6.16562 7.93434C5.8532 7.62192 5.34667 7.62192 5.03425 7.93434C4.72183 8.24675 4.72183 8.75329 5.03425 9.06571L6.63425 10.6657C6.94667 10.9781 7.4532 10.9781 7.76562 10.6657L10.9656 7.46571Z", fill: "url(#paint0_linear_467_144)" }),
  /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsxs("linearGradient", { id: "paint0_linear_467_144", x1: 1.59994, y1: 8.50002, x2: 14.3999, y2: 8.50002, gradientUnits: "userSpaceOnUse", children: [
    /* @__PURE__ */ jsx("stop", { stopColor: "var(--svg-stop-color-0,#AA47F7)" }),
    /* @__PURE__ */ jsx("stop", { offset: 1, stopColor: "var(--svg-stop-color-1,#F147EA)" })
  ] }) })
] });
const ForwardRef = forwardRef(SvgCheckCircleGradientSolid);
export default ForwardRef;
ForwardRef.displayName = "CheckCircleGradientSolidIcon";
